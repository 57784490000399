<template>
	<PopupThreeColWA name="traitement"
	text="<h3>Traitement des eaux résiduaires (TER)</h3><br/><p>La station de traitement des eaux de la raffinerie a pour rôle de traiter les différents effluents afin de rejeter en Loire une eau propre conforme aux normes en vigueur.</p>"
	animation="static/animation/TER.json">
	<picture slot="thumbnail__1">
		<img class="lazy"
		sizes="(max-width: 1366px) 300px,
		(max-width: 1920px) 410px,
		970px"
		data-srcset="
		img/popup/TER_gxddis_c_scale_w_350.jpg 350w,
		img/popup/TER_gxddis_c_scale_w_707.jpg 707w,
		img/popup/TER_gxddis_c_scale_w_970.jpg 970w"
		data-src="img/popup/TER_gxddis_c_scale_w_970.jpg"
		src="static/logo-total.jpg"
		alt="">
	</picture>
</PopupThreeColWA>
</template>

<script>
	export default {
		components: {
			PopupThreeColWA: () => import('@/components/PopupThreeColWA.vue'),
		},
	}
</script>
